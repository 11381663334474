import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/pro-regular-svg-icons";

import { AuthContext } from "../../store/AuthContext";
import MobileNavigationModal from "./MobileNavigationModal";
import LanguageSelection from "./LanguageSelection";
import HeaderProfile from "./HeaderProfile";
import LastConfirmWarningModal from "./LastConfirmWarningModal";
import DropdownMenu from "./DropdownMenu";
import CameroonLogo from "../../images/logo-h-cameroon.svg";
import LebanonLogo from "../../images/logo-h-lebanon.svg";
import RwandaLogo from "../../images/logo-h-rwanda.svg";
import i18next from "i18next";
import { getHeaderTabs } from "../../utils/AdminUtils";

import useRole from "../../hooks/useRole";

const MainContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { isClinicAdmin } = useRole();
  const { authData, logout } = useContext(AuthContext);
  const [showLangs, setShowLangs] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMobileNavigation, setShowMobileNavigtaion] = useState(false);
  const [showProfileHeader, setShowProfileHeader] = useState(false);
  const [showLogoutWarningModal, setShowLogoutWarningModal] = useState(false);
  const [showFixedNavigationHeader, setShowFixedNavigationHeader] = useState(false);

  const navigationHeaderContainer = useRef<HTMLElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (showLangs) {
      setShowProfileMenu(false);
    }
  }, [showLangs]);

  useEffect(() => {
    if (showProfileMenu) {
      setShowLangs(false);
    }
  }, [showProfileMenu]);

  useEffect(() => {
    const navigationHeaderTop = navigationHeaderContainer.current?.offsetTop;
    const navigationHeaderHeight = navigationHeaderContainer.current?.offsetHeight;

    const onWindowScroll = () => {
      if (window.innerWidth >= 768) {
        window.scrollY >= navigationHeaderTop! + navigationHeaderHeight!
          ? !showFixedNavigationHeader && setShowFixedNavigationHeader(true)
          : setShowFixedNavigationHeader(false);
      }
    };
    window.addEventListener("scroll", onWindowScroll);

    return () => window.removeEventListener("scroll", onWindowScroll);
  }, [showFixedNavigationHeader]);

  const selectLogo = () => {
    if (process.env.REACT_APP_Country === "Cameroon") {
      return CameroonLogo;
    } else if (process.env.REACT_APP_Country === "Lebanon") {
      return LebanonLogo;
    } else if (process.env.REACT_APP_Country === "Rwanda") {
      return RwandaLogo;
    }
  };

  return (
    <div className="flex flex-col min-h-screen" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
      <div className="bg-gray-f5 z-30 fixed w-full top-0 left-0 md:static">
        <header className="flex items-center justify-between alignment  py-4 md:py-6">
          <img data-testid="header-logo" className="w-44 md:w-auto" src={selectLogo()} alt="CIMA" />
          <div className="hidden items-center md:flex">
            <LanguageSelection showLangs={showLangs} setShowLangs={setShowLangs} />
            <p className={` ${i18next.language === "ar" ? "ml-2 mr-6" : "ml-6 mr-2"} capitalize text-black27 text-sm`}>
              {t("welcome", { authData })}
            </p>
            <HeaderProfile
              showProfileMenu={showProfileMenu}
              setShowProfileMenu={setShowProfileMenu}
              onLogoutButtonClicked={() => setShowLogoutWarningModal(true)}
            />
          </div>
          <div className="flex items-center md:hidden ">
            <FontAwesomeIcon
              className="text-blue-primary cursor-pointer"
              icon={faBars}
              onClick={() => setShowMobileNavigtaion(true)}
            />
            <FontAwesomeIcon
              className="text-blue-primary cursor-pointer mx-4"
              icon={faUser}
              onClick={() => setShowProfileHeader((preShowUserProfile) => !preShowUserProfile)}
            />
            <div
              className={`flex items-center justify-end bg-gray-f5 p-3 px-4 border-t border-gray-f2 absolute top-14 left-0 right-0 duration-500 ${
                showProfileHeader ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <LanguageSelection showLangs={showLangs} setShowLangs={setShowLangs} />
              <p
                className={` ${i18next.language === "ar" ? "ml-2 mr-6" : "ml-6 mr-2"} capitalize text-black27 text-sm`}
              >
                {t("welcome", { authData })}
              </p>
              <HeaderProfile
                showProfileMenu={showProfileMenu}
                setShowProfileMenu={setShowProfileMenu}
                onLogoutButtonClicked={() => setShowLogoutWarningModal(true)}
              />
            </div>
            <MobileNavigationModal
              sidebarClassName={`${showMobileNavigation ? "translate-x-0" : "-translate-x-full"}`}
              backdropclassName={`${showMobileNavigation ? "block" : "hidden"}`}
              onBackdrop={() => setShowMobileNavigtaion(false)}
            />
          </div>
        </header>

        <header ref={navigationHeaderContainer} className="bg-blue-primary">
          <nav className="alignment py-4 hidden md:flex">
            {getHeaderTabs().map((link, index) => (
              <NavLink
                key={index}
                activeClassName="bg-white51"
                className="text-white px-4 hover:bg-white51 rounded py-2 mx-2"
                to={link.route}
              >
                {t(link.title)}
              </NavLink>
            ))}
            {!isClinicAdmin() && (
              <>
                <DropdownMenu
                  title={t("reports")}
                  options={[
                    {
                      name: t("vaccine_utilization"),
                      location: "/vaccine-utilization",
                    },
                  ]}
                  rounded={true}
                />

                <DropdownMenu
                  title={t("settings")}
                  options={[
                    {
                      name: t("health_messages"),
                      location: "/health-messages",
                    },
                    {
                      name: t("vaccination_details"),
                      location: "/vaccination-details",
                    },
                    { name: t("areas"), location: "/areas" },
                  ]}
                  rounded={true}
                />
              </>
            )}
          </nav>
        </header>

        <header
          className={`bg-white shadow-lg fixed top-0 w-full duration-500 ${
            showFixedNavigationHeader ? "translate-y-0" : " -translate-y-[160px]"
          }`}
        >
          <nav className="alignment py-4 hidden md:flex">
            {getHeaderTabs().map((link, index) => (
              <NavLink
                key={index}
                activeClassName="bg-blue-e1"
                className="text-blue-primary px-4 py-2 hover:bg-blue-e1 rounded mx-2"
                to={link.route}
              >
                {t(link.title)}
              </NavLink>
            ))}

            {!isClinicAdmin() && (
              <>
                <DropdownMenu
                  title={t("reports")}
                  options={[
                    {
                      name: t("vaccine_utilization"),
                      location: "/vaccine-utilization",
                    },
                  ]}
                  rounded={true}
                  secondary={true}
                />

                <DropdownMenu
                  title={t("settings")}
                  options={[
                    {
                      name: t("health_messages"),
                      location: "/health-messages",
                    },
                    {
                      name: t("vaccination_details"),
                      location: "/vaccination-details",
                    },
                    { name: t("areas"), location: "/areas" },
                  ]}
                  rounded={true}
                  secondary={true}
                />
              </>
            )}
          </nav>
        </header>
      </div>
      <main className={`flex-1 w-full relative pt-[60px] md:pt-8 ${showFixedNavigationHeader && "md:pt-[104px]"}`}>
        {children}
      </main>
      {showLogoutWarningModal && (
        <LastConfirmWarningModal
          onCancel={() => setShowLogoutWarningModal(false)}
          onConfirm={() => {
            logout();
          }}
          onBackdrop={() => setShowLogoutWarningModal(false)}
          titleKey="logout"
          messageKey="logout__message"
          confirmButtonLabelKey="yes_logout"
        />
      )}
    </div>
  );
};

export default MainContainer;
